import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "../images/JB-LOGO.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faXmark } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [click, setClick] = useState(false);

  return (
    <div className="container-fluid">
      <nav className="d-flex bg-dark align-items-center px-5 py-2 justify-content-between fixed-top">
        <div className="logo">
          <img src={Logo} alt="logo" height={54} />
        </div>

        <div className="my-nav d-flex align-items-center">
          {/* eslint-disable-next-line */}
          <ul
            className={`navbar-nav flex-md-row align-items-center ${
              click ? "active" : "inactive"
            } `}
            onClick={() => setClick(false)}
          >
            <li className="nav-item mx-3">
              <Link className="nav-link py-0" to="#">
                Home
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link className="nav-link py-0" to="#footer">
                About
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link className="nav-link py-0" to="#our-services">
                Services
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link className="nav-link py-0" to="/gallery">
                Gallery
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link className="nav-link py-0 text-nowrap" to="#">
                Aari blouse
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link className="nav-link py-0" to="#footer">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* eslint-disable-next-line */}
        <div className="res-menu" onClick={() => setClick(!click)}>
          <FontAwesomeIcon
            id="menu"
            icon={click ? faXmark : faBarsStaggered}
            size="2x"
          />
        </div>
      </nav>
      {/* <MyMarquee /> */}
    </div>
  );
};

export default Navbar;
