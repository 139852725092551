/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function Seo({ lang, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
    >
      {/* <!-- Global site tag (gtag.js) - Google Ads: 10892317535 --> */}
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
};

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
