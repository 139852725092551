import React from "react";
import ContactUs from "../ContactUs";
import Seo from "../seo";
import About from "./About";
import BannerSection from "./BannerSection";
import Services from "./Services";
import Testimonies from "./Testimonies";

const Home = () => {
  return (
    <>
      <Seo title="Home" />
      <BannerSection />
      <About />
      <Services />
    </>
  );
};

export default Home;
