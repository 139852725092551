import React, { useState } from "react";
import whatsApp from "../images/whatsapp-new.svg";

const ContactUs = () => {
	const [show, setShow] = useState(false);
	return (
		<div className="contact_us_bubble d-flex align-items-center">
			{/* <div className="mx-3 fb_link">
        <a rel="noreferrer" href="https://www.facebook.com/jenimakeover" target={"_blank"}>
          <svg viewBox="0 0 64 64" width="42px" height="42px">
            <g id="facebook">
              <path
                fill="#fff"
                d="M 32 8 C 18.769531 8 8 18.769531 8 32 C 8 45.230469 18.769531 56 32 56 C 45.230469 56 56 45.230469 56 32 C 56 18.769531 45.230469 8 32 8 Z M 32 12 C 43.070312 12 52 20.929688 52 32 C 52 42.054688 44.625 50.324219 34.976562 51.757812 L 34.976562 37.832031 L 40.671875 37.832031 L 41.566406 32.046875 L 34.976562 32.046875 L 34.976562 28.886719 C 34.976562 26.484375 35.765625 24.351562 38.011719 24.351562 L 41.621094 24.351562 L 41.621094 19.304688 C 40.988281 19.21875 39.644531 19.03125 37.109375 19.03125 C 31.8125 19.03125 28.710938 21.828125 28.710938 28.199219 L 28.710938 32.046875 L 23.265625 32.046875 L 23.265625 37.832031 L 28.710938 37.832031 L 28.710938 51.707031 C 19.21875 50.140625 12 41.945312 12 32 C 12 20.929688 20.929688 12 32 12 Z M 32 12 "
              />
            </g>
          </svg>
        </a>
      </div>
      <div className="mx-3 insta_link">
        <a rel="noreferrer" href="https://www.instagram.com/jenis_blush/" target={"_blank"}>
          <svg viewBox="0 0 52 52" width="30px" height="30px">
            <g id="instagram">
              <path
                fill="#fff"
                d="M 15.09375 0 C 6.78125 0 0 6.78125 0 15.09375 L 0 36.90625 C 0 45.21875 6.78125 52 15.09375 52 L 36.90625 52 C 45.21875 52 52 45.21875 52 36.90625 L 52 15.09375 C 52 6.78125 45.21875 0 36.90625 0 Z M 15.09375 4 L 36.90625 4 C 43.054688 4 48 8.9375 48 15.09375 L 48 36.90625 C 48 43.054688 43.0625 48 36.90625 48 L 15.09375 48 C 8.945312 48 4 43.0625 4 36.90625 L 4 15.09375 C 4 8.945312 8.9375 4 15.09375 4 Z M 41 8 C 39.34375 8 38 9.34375 38 11 C 38 12.65625 39.34375 14 41 14 C 42.65625 14 44 12.65625 44 11 C 44 9.34375 42.65625 8 41 8 Z M 26 12 C 18.289062 12 12 18.289062 12 26 C 12 33.710938 18.289062 40 26 40 C 33.710938 40 40 33.710938 40 26 C 40 18.289062 33.710938 12 26 12 Z M 26 16 C 31.546875 16 36 20.453125 36 26 C 36 31.546875 31.546875 36 26 36 C 20.453125 36 16 31.546875 16 26 C 16 20.453125 20.453125 16 26 16 Z M 26 16 "
              />
            </g>
          </svg>
        </a>
      </div> */}
			{show && (
				<div className="social_name mx-2 badge bg-success">Whatsapp</div>
			)}
			{/* eslint-disable */}
			<div
				className="wa_link"
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
			>
				<a
					rel="noreferrer"
					href="https://api.whatsapp.com/send?phone=917550128707"
					target={"_blank"}
				>
					<img src={whatsApp} alt="WA" height={54} />
				</a>
			</div>
		</div>
	);
};
export default ContactUs;
