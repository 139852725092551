import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Services = () => {
  return (
    <div className="mx-3 py-4" id="our-services">
      <div className="">
        <div className="service">
          <div className="service_text">Our make-up services...</div>
          <div className="sub-text">We provide quality services</div>
          <div className="d-flex">
            <div className="span_line me-4"></div>
            <div className="ms-3 my-4">
              <div className="text-theme">
                Keep an eye of her; she is destined to make it even bigger, so if you want to look
                like a diva on your big day be ready to spend a little more money on make-up by her,
                and it will be totally worth it. She usually travels to the location and also
                provides a paid trail option.
              </div>
              <div className="mt-3">
                We are providing also a waterproof make-over, glossy make-over, matte look
                make-over, etc... with affordable price (including offers based on season)...!!
              </div>
            </div>
          </div>
        </div>

        {/* Ads */}

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2837136443568743"
          crossOrigin="anonymous"
        ></script>
        <ins
          class="adsbygoogle"
          style={{ display: "block", textAlign: "center" }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-2837136443568743"
          data-ad-slot="5608074699"
        ></ins>
        <script
          dangerouslySetInnerHTML={{ __html: `(adsbygoogle = window.adsbygoogle || []).push({});` }}
        ></script>

        {/* Services */}

        <div className="container">
          <div className="service_cards row justify-content-center">
            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <StaticImage
                  src={"../../images/services/Photoshoot.jpg"}
                  alt="Photo shoot"
                  placeholder="blurred"
                  width={480}
                  height={320}
                />
              </div>
              <div className="service_button text-center bg-dark">Photoshoot makeup</div>
            </div>

            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <StaticImage
                  src={"../../images/services/Ramp walk.jpg"}
                  alt="Photo shoot"
                  placeholder="blurred"
                  width={480}
                  height={320}
                />
              </div>
              <div className="service_button text-center bg-dark">Western makeup</div>
            </div>

            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <StaticImage
                  src={"../../images/services/Nuptial.jpg"}
                  alt="Photo shoot"
                  placeholder="blurred"
                  width={480}
                  height={320}
                />
              </div>
              <div className="service_button text-center bg-dark">Nuptial makeup</div>
            </div>

            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <StaticImage
                  src={"../../images/services/Puberty.jpg"}
                  alt="Photo shoot"
                  placeholder="blurred"
                  width={480}
                  height={320}
                />
              </div>
              <div className="service_button text-center bg-dark">Traditional makeup</div>
            </div>

            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <StaticImage
                  src={"../../images/services/Betrothal.jpg"}
                  alt="Photo shoot"
                  placeholder="blurred"
                  width={480}
                  height={320}
                />
              </div>
              <div className="service_button text-center bg-dark">Betrothal makeup</div>
            </div>

            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <StaticImage
                  src={"../../images/services/CW.jpg"}
                  alt="Photo shoot"
                  placeholder="blurred"
                  width={480}
                  height={320}
                />
              </div>
              <div className="service_button text-center bg-dark">Christian makeup</div>
            </div>
            <div className="service_card col-md-4">
              <div className="card rounded-0 shadow m-4">
                <script
                  async
                  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2837136443568743"
                  crossOrigin="anonymous"
                ></script>
                <ins
                  class="adsbygoogle"
                  style={{ display: "block" }}
                  data-ad-format="fluid"
                  data-ad-layout-key="-8e+ez-1j-3k+cg"
                  data-ad-client="ca-pub-2837136443568743"
                  data-ad-slot="5407381256"
                ></ins>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `(adsbygoogle = window.adsbygoogle || []).push({});`,
                  }}
                ></script>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script
        async
        custom-element="amp-ad"
        src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"
      ></script>
      <amp-ad
        width="100vw"
        height="320"
        type="adsense"
        data-ad-client="ca-pub-2837136443568743"
        data-ad-slot="1683758660"
        data-auto-format="mcrspv"
        data-full-width=""
      >
        <div overflow=""></div>
      </amp-ad>
    </div>
  );
};

export default Services;
