import React from "react";
import "../../styles/about.css";
import profile from "../../images/About/PSX_20221025_012837.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintbrush } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function About() {
  const aboutTabMenu = ["Main Skill", "Awards", "experience"];
  const [abutTab, setAboutTab] = useState(0);
  return (
    <div className="about_section d-flex justify-content-center align-items-center">
      <div className=" container d-flex justify-content-around align-items-center flex-wrap">
        <div className="img_container">
          <img className="" src={profile} alt="" />
        </div>
        <div className=" about_content">
          <h2>About Me</h2>
          <p>
            Jeni's Blush is a certified bridal makeup artist who trained with
            the best before becoming one of the most well-known independent
            freelance bridal makeup artists in the wedding industry around
            Chennai and Thiruvallur.
          </p>
          <p>
            She is incredibly versatile and our work is impeccable, whether it's
            an ethereal or regal north Indian wedding or a large south Indian
            wedding.
          </p>
          <p>
            Without causing their clients any difficulty, we can offer all the
            accessories they'll need for the day.
          </p>
          <div className="about_tabs">
            <ul className="d-flex">
              {aboutTabMenu.map((x, idx) => (
                <li key={idx} onClick={() => setAboutTab(idx)}>
                  {x}
                </li>
              ))}
            </ul>
            {abutTab === 0 ? (
              <div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  one
                </div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  two
                </div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  three
                </div>
              </div>
            ) : abutTab === 1 ? (
              <div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  four
                </div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  five
                </div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  six
                </div>
              </div>
            ) : abutTab === 2 ? (
              <div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  seven
                </div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  eight
                </div>
                <div>
                  <FontAwesomeIcon className="brush" icon={faPaintbrush} />
                  nine
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
