import * as React from "react";
import { useState, useEffect } from "react";
import "../styles/bootstrap.css";
import "../styles/index.scss";
import Home from "../components/Home";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { HighlightOffRounded } from "@mui/icons-material";
import referImg from "../images/Final Refer.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

const IndexPage = () => {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopup(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const popupClose = () => {
    setPopup(false);
  };
  return (
    <>
      {/* <Dialog open={popup} onClose={popupClose} className="w-100 refer_popup">
        <span
          className="d-flex justify-content-end position-relative close_refer"
          onClick={popupClose}
          onKeyUp={popupClose}
          role="button"
          tabIndex={-1}
        >
          <HighlightOffRounded style={{ color: "#fff" }} />
        </span>
        <DialogContent className="refer_img">
          <img src={referImg} alt="refer" />
        </DialogContent>
      </Dialog> */}
      <Navbar />
      <Home />
      <Footer />
      <ContactUs />
    </>
  );
};

export default IndexPage;
